import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container, Box,
} from '@mui/material';
import { format } from 'date-fns';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { estimateActions } from '../../../redux/sales/estimate/estimateState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import SearchMultipleSelectBox from '../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import EstimateDialog from './EstimateDialog';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';

class EstimateList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'order_no', label: '注文番号' },
      { id: 'status', label: '状況' },
      { id: 'app_status', label: '承認' },
      { id: 'contract_type', label: '契約区分' },
      { id: 'project_name', label: 'プロジェクト' },
      { id: 'period', label: '契約期間' },
      { id: 'order_amount', label: '注文' },
      { id: 'claim_amount', label: '請求' },
    ];

    let searchCondition = {
      searchKeyword: '',
      searchProjectId: '',
      searchStatus: [],
      searchOrderNo: '',
      searchAppStatus: [],
      searchMonthStart: format(new Date(), 'yyyy/MM/dd'),
      searchMonthEnd: format(new Date(), 'yyyy/MM/dd'),
      ball: '',
      searchDepartment: [],
      searchType: [],
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.estimateInfo.searchCondition) {
      searchCondition = props.estimateInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      select: {
        codes: [], appStatus: [], clients: [], departments: [],
      },
      isDetailOpen: false,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.props.dispatch(estimateActions.getSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.estimateInfo.datas !== this.props.estimateInfo.datas) {
      if (this.props.estimateInfo.datas) {
        this.setDatas(this.props.estimateInfo.datas);
      }
    }
    if (prevProps.estimateInfo.select !== this.props.estimateInfo.select) {
      if (this.props.estimateInfo.select) {
        this.setSelect(this.props.estimateInfo.select);
      }
    }
    if (prevProps.estimateInfo.isDeleteSuccess
      !== this.props.estimateInfo.isDeleteSuccess) {
      if (this.props.estimateInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(estimateActions.setSearchCondition(searchCondition));
    this.props.dispatch(estimateActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(estimateActions.deleteData({ ids }));
  };

  onAdd = () => {
    this.setState({ isDetailOpen: true });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      select,
      isDetailOpen,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="注文番号" />
              <TextField size="small" value={searchCondition.searchOrderNo} fullWidth name="searchOrderNo" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="状況" />
              <SearchMultipleSelectBox
                name="searchStatus"
                options={select.codes}
                values={searchCondition.searchStatus}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="ステータス" />
              <SearchMultipleSelectBox
                name="searchAppStatus"
                options={select.appStatus}
                values={searchCondition.searchAppStatus}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="ボール" />
              <SelectSyain onChange={this.onChange} name="ball" value={searchCondition.ball} targetMonth={searchCondition.searchMonth} />
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="部" />
              <SearchMultipleSelectBox
                name="searchDepartment"
                options={select.departments}
                values={searchCondition.searchDepartment}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="契約区分" />
              <SearchMultipleSelectBox
                name="searchType"
                options={[{ id: '1', name: '準委任' }, { id: '2', name: '請負' }, { id: '3', name: '保守' }]}
                multiple
                values={searchCondition.searchType}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="契約期間" />
              <Box display="flex">
                <Box>
                  <MonthPicker value={searchCondition.searchMonthStart} name="searchMonthStart" handleChange={this.onChange} />
                </Box>
                <Box alignItems="center" display="flex" mx={1}>～</Box>
                <Box>
                  <MonthPicker value={searchCondition.searchMonthEnd} name="searchMonthEnd" handleChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="プロジェクト名／取引先名" />
              <TextField size="small" value={searchCondition.searchKeyword} fullWidth name="searchKeyword" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DeleteDataTable
          url={urls.ESTIMATE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          onAddOpen={this.onAdd}
          procName="注文"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <EstimateDialog isOpen={isDetailOpen} onClose={this.onClose} estimateId={null} />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  estimateInfo: state.estimateStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(EstimateList);
