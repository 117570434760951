/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable react/display-name */
import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Table, Box, TableContainer,
  Paper, TableHead, TableRow, TableCell, TableBody, IconButton,
} from '@mui/material';
import {
  ModeEdit as ModeEditIcon, Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  getData, isRested, getMonthly,
} from './work-plan-proc';
import './test.css';

const RestColorCell = styled(TableCell)(({ rested }) => ({
  ...(rested === 'true' && {
    color: 'red',
  }),
}));

export default function WorkTable(props) {
  const WorkMemoTable = React.memo(({
    getData, getRested, monthly, datas, restCalendars, onDayClick, onDayDeleteClick,
  }) => (
    <Box>
      <TableContainer sx={{ maxHeight: 800 }} component={Paper}>
        <Table size="medium" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>日</TableCell>
              <TableCell>曜日</TableCell>
              <TableCell>開始</TableCell>
              <TableCell>終了</TableCell>
              <TableCell>休憩</TableCell>
              <TableCell>作業</TableCell>
              <TableCell>工数</TableCell>
              <TableCell>場所</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {monthly.map((row) => {
              const data = getData(datas, row.formatDate);
              const rested = getRested(restCalendars, row.formatDate);
              return (
                <TableRow
                  key={row.day}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { background: '#f1f3f4' } }}
                >
                  <RestColorCell component="th" scope="row" rested={rested.toString()}>{row.day}</RestColorCell>
                  <RestColorCell rested={rested.toString()}>{row.week}</RestColorCell>
                  <TableCell>{data.startTime}</TableCell>
                  <TableCell>{data.endTime}</TableCell>
                  <TableCell>{data.restTime}</TableCell>
                  <TableCell>{data.workTime}</TableCell>
                  <TableCell>{data.costTime}</TableCell>
                  <TableCell>{data.placeName}</TableCell>
                  <TableCell>
                    <Box>
                      <IconButton component="label" onClick={(event) => onDayClick(event, row)}>
                        <ModeEditIcon />
                      </IconButton>
                      {data.id && (
                      <IconButton component="label" onClick={(event) => onDayDeleteClick(event, data, row)}>
                        <DeleteIcon />
                      </IconButton>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ));

  const monthly = getMonthly(props.targetMonth);
  return (
    <WorkMemoTable
      onDayDeleteClick={props.onDayDeleteClick}
      onDayClick={props.onDayClick}
      monthly={monthly}
      datas={props.datas}
      restCalendars={props.restCalendars}
      getRested={isRested}
      getData={getData}
    />
  );
}
