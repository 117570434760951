/* eslint-disable no-restricted-globals */
import React from 'react';
import { format, isValid, isBefore } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { connect } from 'react-redux';
import {
  Button, DialogTitle, DialogContent, DialogActions, Grid, LinearProgress,
  IconButton, ToggleButtonGroup, ToggleButton, Box, Divider,
  FormControl, Select, MenuItem, FormHelperText, TextField,
} from '@mui/material';
import {
  Business as BusinessIcon, Home as HomeIcon, Close as CloseIcon, Add as AddIcon,
  OtherHouses as OtherHousesIcon, Clear as ClearIcon, ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import TimePicker from '../../../components/atoms/timePicker/TimePicker';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { saveTargetDate, getRestWorkTime, isRested } from './work-plan-proc';
import { workPlanActions } from '../../../redux/work/workPlan/workPlanState';
import ProjectSelectDialog from '../workResultInput/ProjectSelectDialog';

class WorkPlanInputDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        startTime: '',
        endTime: '',
        placeCode: '',
        placeOther: '',
      },
      summary: {
        workTime: 0,
        restTime: 0,
        costTime: 0,
        diffTime: 0,
      },
      formatDate: {
        formatDate: '',
        formatMonth: '',
        month: '',
        date: '',
        week: '',
      },
      costs: [],
      restData: {},
      repeat: 0,
      rested: false,
      isOpenProject: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.initData();
      }
    }
    if (this.props.workPlanInfo.rest !== prevProps.workPlanInfo.rest) {
      if (this.props.workPlanInfo.rest) {
        this.setRestData(this.props.workPlanInfo.rest);
      }
    }
    if (this.props.workPlanInfo.data !== prevProps.workPlanInfo.data) {
      if (this.props.workPlanInfo.data) {
        this.setData(this.props.workPlanInfo.data);
      }
    }
    if (this.props.workPlanInfo.costs !== prevProps.workPlanInfo.costs) {
      if (this.props.workPlanInfo.costs) {
        this.setCosts(this.props.workPlanInfo.costs);
      }
    }
  }

  setRestData = (restData) => {
    this.setState({ restData });
  };

  setCosts = (costs) => {
    this.setState({ costs });
  };

  setData = (dbData) => {
    const { data, costs } = dbData;
    const { targetDateItem, restCalendars } = this.props;
    const rested = isRested(restCalendars, targetDateItem.formatDate);
    this.setState({
      summary: {
        restTime: data.restTime ? data.restTime : 0,
        workTime: data.workTime ? data.workTime : 0,
        costTime: data.costTime ? data.costTime : 0,
        diffTime: data.diffTime ? data.diffTime : 0,
      },
      form: {
        startTime: data.startTime,
        endTime: data.endTime,
        placeCode: data.placeCode,
        placeOther: data.placeOther || '',
      },
      repeat: 0,
      rested,
      costs,
      isLoading: false,
    });
  };

  initData = () => {
    const { targetDateItem, syainId } = this.props;
    const formatDate = format(new Date(targetDateItem.formatDate), 'yyyy年MM月dd日(EEEEEE)', { locale: ja });
    const formatMonth = format(new Date(targetDateItem.formatDate), 'yyyy年MM月', { locale: ja });
    const month = format(new Date(targetDateItem.formatDate), 'yyyy/MM', { locale: ja });
    const { week } = targetDateItem;
    this.props.dispatch(workPlanActions.getWorkPlanDetail({
      syainId, targetDate: targetDateItem.formatDate,
    }));
    this.setState({
      formatDate: {
        formatDate,
        formatMonth,
        month,
        week,
        date: targetDateItem.formatDate,
      },
      isLoading: true,
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onTimeChange = (event) => {
    const { name, value } = event.target;
    const { form, summary } = this.state;
    let targetValue = value;
    if (value && value.length === 5) {
      const hour = value.slice(0, 2);
      const minute = value.slice(-2);
      targetValue = `${hour}:${Math.floor(minute / 15) * 15}`;
    }
    const { restData } = this.state;
    const { startTime, endTime } = form;
    const start = name === 'startTime' ? targetValue : startTime;
    const end = name === 'endTime' ? targetValue : endTime;
    const restWorkData = getRestWorkTime(start, end, restData);
    this.setState({
      form: {
        ...form,
        [name]: targetValue,
      },
      summary: {
        restTime: restWorkData.restTime,
        workTime: restWorkData.workTime,
        costTime: summary.costTime,
        diffTime: restWorkData.workTime - summary.costTime,
      },
    });
  };

  onPlaceChange = (_, placeCode) => {
    if (!placeCode) {
      return;
    }
    const { form } = this.state;
    this.setState({ form: { ...form, placeCode } });
  };

  onOpenProject = () => {
    this.setState({ isOpenProject: true });
  };

  onCloseProject = () => {
    this.setState({ isOpenProject: false });
  };

  onClickCopy = () => {
    const { formatDate } = this.state;
    const { syainId } = this.props;
    this.props.dispatch(workPlanActions.copyCost({ syainId, targetDate: formatDate.date }));
  };

  onClickSave = () => {
    const {
      formatDate, form, repeat, costs, summary,
    } = this.state;
    const {
      syainId, onClickClose, restCalendars,
    } = this.props;
    const targetMonths = saveTargetDate({
      month: formatDate.month,
      targetDate: formatDate.date,
      type: repeat,
      fnsWeek: formatDate.week,
      rests: restCalendars,
    });
    this.props.dispatch(
      workPlanActions.saveWorkPlan({
        syainId,
        targetMonths,
        startTime: form.startTime,
        endTime: form.endTime,
        placeCode: form.placeCode,
        placeOther: form.placeOther,
        workTime: summary.workTime,
        restTime: summary.restTime,
        costs,
      }),
    );
    onClickClose();
  };

  onAddCost = (_, datas) => {
    const { costs } = this.state;
    let costList = costs || [];
    datas.forEach((item) => {
      const cost = { projectId: item.projectId, projectName: item.projectName, cost: 0 };
      costList = [...costList, cost];
    });

    this.setState({
      isOpenProject: false,
      costs: costList,
    });
  };

  onCostChange = (event, index) => {
    const { costs, summary } = this.state;
    const costList = [...costs];
    let obj = costList[index];
    obj = {
      ...obj,
      cost: event.target.value,
    };
    let costTm = 0;
    costList.splice(index, 1, obj);
    costList.forEach((item) => {
      const value = item.cost ? item.cost : 0;
      costTm += parseInt(value, 10);
    });
    costTm = !isNaN(costTm) ? costTm : 0;
    const workTm = summary.workTime ? parseInt(summary.workTime, 10) : 0;
    const diffTime = workTm - costTm;
    this.setState({
      costs: costList,
      summary: {
        ...summary,
        diffTime,
        costTime: costTm,
      },
    });
  };

  onRemarksChange = (event, index) => {
    const { costs } = this.state;
    const costList = [...costs];
    let obj = costList[index];
    obj = {
      ...obj,
      remarks: event.target.value,
    };
    costList.splice(index, 1, obj);
    this.setState({
      costs: costList,
    });
  };

  onClickDeleteCost = (_, index) => {
    const { costs } = this.state;
    const costList = [...costs];
    costList.splice(index, 1);
    this.setState({
      costs: costList,
    });
  };

  saveDisabled() {
    const {
      startTime, endTime, placeCode, placeOther,
    } = this.state.form;
    if (!startTime || !endTime || !placeCode) {
      return true;
    }
    if (placeCode === '3' && !placeOther) {
      return true;
    }
    const start = new Date(`1999/12/31 ${startTime}`);
    const end = new Date(`1999/12/31 ${endTime}`);
    if (!isValid(start) || !isValid(end)) {
      return true;
    }
    if (isBefore(end, start)) {
      return true;
    }
    return false;
  }

  render() {
    const { onClickClose, isOpen, syainId } = this.props;
    const {
      formatDate, summary, repeat, costs, isOpenProject, rested, form, isLoading,
    } = this.state;

    return (
      <>
        <Dialog
          onClose={onClickClose}
          isOpen={isOpen}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {`${formatDate.formatDate}の予定`}
            <IconButton
              aria-label="close"
              onClick={onClickClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {isLoading && (<LinearProgress />)}
          <DialogContent dividers>
            {!isLoading && (
              <Box>
                <Box mb={2}>
                  <FormControl fullWidth>
                    <Select
                      value={repeat}
                      name="repeat"
                      onChange={this.onChange}
                    >
                      <MenuItem value={0}>繰り返さない</MenuItem>
                      {!rested && (<MenuItem value={1}>以降の毎週平日</MenuItem>)}
                      <MenuItem value={2}>
                        以降の毎週
                        {formatDate.week}
                        曜日
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {formatDate.formatMonth}
                      にのみ適用
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display="flex" mb={2}>
                  <Box mr={2} sx={{ width: '135px' }}>
                    <TimePicker handleChange={this.onTimeChange} name="startTime" value={form.startTime} />
                  </Box>
                  <Box sx={{ width: '135px' }}>
                    <TimePicker handleChange={this.onTimeChange} name="endTime" value={form.endTime} />
                  </Box>
                </Box>
                <Box mb={2}>
                  <ToggleButtonGroup
                    color="primary"
                    value={form.placeCode}
                    exclusive
                    onChange={this.onPlaceChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="1">
                      <BusinessIcon />
                      本社
                    </ToggleButton>
                    <ToggleButton value="2">
                      <HomeIcon />
                      自宅
                    </ToggleButton>
                    <ToggleButton value="3">
                      <OtherHousesIcon />
                      その他
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box>
                  {form.placeCode === '3' && (
                    <TextField
                      value={form.placeOther}
                      name="placeOther"
                      fullWidth
                      placeholder="その他場所"
                      onChange={this.onChange}
                    />
                  )}
                </Box>
                <Box mt={2}>
                  <Divider />
                  <Box display="flex" mt={2} mb={2}>
                    <Box style={{ flex: '1 50%' }}>工数</Box>
                    <Button variant="outlined" size="small" onClick={this.onClickCopy} startIcon={<ContentCopyIcon />}>前回分コピー</Button>
                  </Box>
                  <Box mt={1} mb={1}>
                    {costs.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <Grid container mt={1}>
                          <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                            <div>{item.projectName}</div>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField size="small" variant="outlined" value={item.cost} inputProps={{ type: 'number' }} onChange={(event) => this.onCostChange(event, index)} />
                          </Grid>
                        </Grid>
                        <Grid container mt={1}>
                          <Grid item xs={11}>
                            <TextField fullWidth size="small" variant="outlined" value={item.remarks} onChange={(event) => this.onRemarksChange(event, index)} />
                          </Grid>
                          <Grid item xs={1} style={{ display: 'flex', textAlign: 'center' }}>
                            <IconButton size="small" onClick={(event) => this.onClickDeleteCost(event, index)}>
                              <ClearIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Box>
                  <Box m={1} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                    <Button variant="outlined" size="small" startIcon={<AddIcon />} onClick={this.onOpenProject}>追加</Button>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" mt={2}>
                  <Box>{`休憩 ${summary.restTime}`}</Box>
                  <Box ml={2}>{`作業 ${summary.workTime}`}</Box>
                  <Box ml={2}>{`工数 ${summary.costTime}`}</Box>
                  <Box ml={2}>{`差分 ${summary.diffTime}`}</Box>
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickClose} color="inherit">キャンセル</Button>
            <Button onClick={this.onClickSave} disabled={this.saveDisabled()}>保存</Button>
          </DialogActions>
        </Dialog>
        <ProjectSelectDialog
          onAddCost={this.onAddCost}
          syainId={syainId}
          isOpen={isOpenProject}
          onClickClose={this.onCloseProject}
          searchDt={formatDate.date}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  workPlanInfo: state.workPlanStore,
});

export default connect(mapStateToProps)(WorkPlanInputDialog);
