import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  Business as BusinessIcon, Home as HomeIcon, ModeEdit as ModeEditIcon, Delete as DeleteIcon,
  OtherHouses as OtherHousesIcon, ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';

export default function CalendarContent(props) {
  const {
    data, item, onDayClick, onDayDeleteClick,
  } = props;
  if (!item.isSomeMonth) {
    return (<div />);
  }
  let linkElement = null;
  if (data.id) {
    let icon = data.placeCode === '1' ? <BusinessIcon fontSize="small" /> : <HomeIcon fontSize="small" />;
    if (data.placeCode === '3') {
      icon = <Tooltip title={data.placeOther}><OtherHousesIcon fontSize="small" /></Tooltip>;
    }
    linkElement = (
      <Box display="flex" justifyContent="center">
        <Box>{icon}</Box>
        <Box>{`${data.startTime}-${data.endTime}`}</Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        {item.day}
      </Box>
      <Box>
        <IconButton onClick={(event) => onDayClick(event, item)} size="small">
          <ModeEditIcon />
        </IconButton>
        {data.id && (
          <IconButton onClick={(event) => onDayDeleteClick(event, data, item)} size="small">
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      <Box>
        {linkElement}
      </Box>
      {(data.id && data.diffTime !== 0) && (
        <Box display="flex" justifyContent="center">
          <ErrorOutlineIcon size="small" color="secondary" />
          {data.diffTime}
        </Box>
      )}
    </Box>
  );
}
