import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Table, TableBody, TableCell, Box,
  TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { format } from 'date-fns';
import { vacationActions } from '../../../redux/office/vacation/vacationState';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import urls from '../../../constants/frontUrls';
import Link from '../../../components/atoms/link/Link';
import Pagination from '../../../components/atoms/pagination/Pagination';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';

class VacationList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
      targetDate: format(new Date(), 'yyyy/MM/dd'),
    };

    if (props.vacationInfo.searchCondition) {
      searchCondition = props.vacationInfo.searchCondition;
    }

    this.headCells = [
      { id: 'syain_mei', label: '名前' },
      { id: 'last_grant_date', label: '最終付与' },
      { id: 'conf_num', label: '確定数' },
      { id: 'plan_num', label: '予定数' },
      { id: 'remauining', label: '残数' },
    ];

    this.search(searchCondition);
    this.state = {
      datas: {
        content: [],
      },
      searchCondition,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vacationInfo.data !== this.props.vacationInfo.data) {
      if (this.props.vacationInfo.data) {
        this.setDatas(this.props.vacationInfo.data);
      }
    }
    if (prevProps.vacationInfo.isDeleteSuccess !== this.props.vacationInfo.isDeleteSuccess) {
      if (this.props.vacationInfo.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  search = (searchCondition) => {
    this.props.dispatch(vacationActions.getData(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChange = (event) => {
    const { value, name } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Box mb={1}>
          <FormTitle title="基準日" />
          <DatePicker value={searchCondition.targetDate} name="targetDate" handleChange={this.onChange} />
        </Box>
        <Paper>
          <TableContainer sx={{ maxHeight: 750 }}>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {this.headCells.map((headCell) => (
                    <TableHeaderCell
                      headCell={headCell}
                      orderBy={searchCondition.orderBy}
                      order={searchCondition.order}
                      key={headCell.id}
                      handleRequestSort={this.handleRequestSort}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.content.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell>
                      <Link to={urls.VACATION_DETAIL + row.id}>{row.syainMei}</Link>
                    </TableCell>
                    <TableCell>{row.lastGrantDate}</TableCell>
                    <TableCell>{row.confNum}</TableCell>
                    <TableCell>{row.planNum}</TableCell>
                    <TableCell>{row.remauining}</TableCell>
                  </TableRow>
                ))}
                {
                  datas.content.length < 1 && (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            totalElements={datas.totalElements}
            rowsPerPage={searchCondition.records}
            page={searchCondition.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  vacationInfo: state.vacationStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(VacationList);
