import { call, put, takeLatest } from 'redux-saga/effects';
import { workPlanActions } from './workPlanState';
import { getDatas, saveData, deleteDatas } from '../../../services/base.service';
import { REST_API } from '../../../constants/serverUrls';

function* getRest() {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_PLAN.WORK_PLAN_REST);
    yield put(workPlanActions.getRestSuccess(result));
  } catch (_) {}
}

function* getWorkPlanList({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_PLAN.WORK_PLAN, payload);
    yield put(workPlanActions.getWorkPlanListSuccess(result));
  } catch (_) {}
}

function* getWorkPlanDetail({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_PLAN.WORK_PLAN_DETAIL, payload);
    yield put(workPlanActions.getWorkPlanDetailSuccess(result));
  } catch (_) {}
}

function* copyCost({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_PLAN.WORK_PLAN_COST_COPY, payload);
    yield put(workPlanActions.copyCostSuccess(result));
  } catch (_) {}
}

function* saveWorkPlan({ payload }) {
  try {
    yield call(saveData, REST_API.WORK.WORK_PLAN.WORK_PLAN, payload);
    yield put(workPlanActions.saveWorkPlanSuccess());
  } catch (_) {}
}

function* deleteWorkPlan({ payload }) {
  try {
    yield call(deleteDatas, REST_API.WORK.WORK_PLAN.WORK_PLAN, payload);
    yield put(workPlanActions.deleteWorkPlanSuccess());
  } catch (_) {}
}

function* workPlanSaga() {
  yield takeLatest('workPlan/getRest', getRest);
  yield takeLatest('workPlan/getWorkPlanList', getWorkPlanList);
  yield takeLatest('workPlan/getWorkPlanDetail', getWorkPlanDetail);
  yield takeLatest('workPlan/copyCost', copyCost);
  yield takeLatest('workPlan/saveWorkPlan', saveWorkPlan);
  yield takeLatest('workPlan/deleteWorkPlan', deleteWorkPlan);
}

export default workPlanSaga;
