import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container,
} from '@mui/material';
import { format } from 'date-fns';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { projectActions } from '../../../redux/sales/project/projectState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import { commonActions } from '../../../redux/common/commonState';
import SearchMultipleSelectBox from '../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import ProjectDialog from './ProjectDialog';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';

class ProjectList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'nick_name', label: '名前' },
      { id: 'client_nick_name', label: '取引先' },
      { id: 'project_type', label: 'プロジェクトタイプ' },
      { id: 'status', label: 'ステータス' },
    ];

    let searchCondition = {
      searchStatus: [],
      searchKeyword: '',
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
      searchType: [],
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.projectInfo.searchCondition) {
      searchCondition = props.projectInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      codes: [],
      isDetailOpen: false,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.props.dispatch(commonActions.getStatuses({ code: 'PJ_STATE' }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectInfo.datas !== this.props.projectInfo.datas) {
      if (this.props.projectInfo.datas) {
        this.setDatas(this.props.projectInfo.datas);
      }
    }
    if (prevProps.common.statuses !== this.props.common.statuses) {
      if (this.props.common.statuses) {
        this.setCodes(this.props.common.statuses);
      }
    }
    if (prevProps.projectInfo.isDeleteSuccess
      !== this.props.projectInfo.isDeleteSuccess) {
      if (this.props.projectInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setCodes = (codes) => {
    this.setState({ codes });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(projectActions.setSearchCondition(searchCondition));
    this.props.dispatch(projectActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(projectActions.deleteData({ ids }));
  };

  onAdd = () => {
    this.setState({ isDetailOpen: true });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      codes,
      isDetailOpen,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="ステータス" />
              <SearchMultipleSelectBox
                name="searchStatus"
                options={codes}
                multiple
                values={searchCondition.searchStatus}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="プロジェクトタイプ" />
              <SearchMultipleSelectBox
                name="searchContract"
                options={[{ id: '1', name: '通常' }, { id: '2', name: '保守' }, { id: '3', name: 'その他' }]}
                multiple
                values={searchCondition.searchContract}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="対象月" />
              <MonthPicker value={searchCondition.targetMonth} name="targetMonth" handleChange={this.onChange} />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title="PJ名／取引先名" />
              <TextField value={searchCondition.searchKeyword} fullWidth name="searchKeyword" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DeleteDataTable
          url={urls.PROJECT_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          onAddOpen={this.onAdd}
          procName="プロジェクト"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <ProjectDialog isOpen={isDetailOpen} onClose={this.onClose} projectId={null} />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  projectInfo: state.projectStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(ProjectList);
