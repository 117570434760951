import React from 'react';
import {
  Page, Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Title, Grid, Col, Box, NumberCol,
} from '../../../../components/pdf/Common';
import syaban from '../../../../asset/syaban.png';

export default function EstimateFile(props) {
  const { estimate, detail } = props.data || {};
  const { isDispTanto } = props;
  return (
    <Document>
      <Page size="A4" wrap>
        <Root>
          <Title>御見積書 兼 注文書</Title>
          <Grid>
            <Col flexBasis={estimate.clientName.length > 20 ? '100%' : '70%'}>
              <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                <Text style={{ textAlign: 'center' }}>{estimate.clientName}</Text>
                {!estimate.contactName && (<Text style={{ marginLeft: '20px' }}>御中</Text>)}
              </Box>
            </Col>
            {estimate.clientName.length <= 20 && (
              <Col flexBasis="30%">
                <Box style={{ flexDirection: 'row', width: '100%' }}>
                  <Box style={{ minWidth: '40px' }}><Text>No.</Text></Box>
                  <Box><Text>{estimate.chumonNo}</Text></Box>
                </Box>
              </Col>
            )}
          </Grid>
          {estimate.clientName.length > 20 && (
            <Grid>
              <Col flexBasis="70%">
                {estimate.contactName && (
                  <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                    <Text style={{ textAlign: 'center' }}>{estimate.contactName}</Text>
                    <Text style={{ marginLeft: '20px' }}>様</Text>
                  </Box>
                )}
              </Col>
              <Col flexBasis="30%">
                <Box style={{ flexDirection: 'row', width: '100%' }}>
                  <Box style={{ minWidth: '40px' }}><Text>No.</Text></Box>
                  <Box><Text>{estimate.chumonNo}</Text></Box>
                </Box>
              </Col>
            </Grid>
          )}
          <Grid>
            <Col flexBasis="70%">
              {(estimate.contactName && estimate.clientName.length <= 20) && (
                <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                  <Text style={{ textAlign: 'center' }}>{estimate.contactName}</Text>
                  <Text style={{ marginLeft: '20px' }}>様</Text>
                </Box>
              )}
            </Col>
            <Col flexBasis="30%">
              <Box style={{ flexDirection: 'row', width: '100%' }}>
                <Box style={{ minWidth: '40px' }}><Text>見積日</Text></Box>
                <Box><Text>{estimate.estimateDate}</Text></Box>
              </Box>
            </Col>
          </Grid>
          <Grid style={{ marginTop: '30px' }}>
            <Col flexBasis="70%">
              <Text style={{ marginBottom: '10px' }}>下記の通り、御見積り申し上げます。</Text>
            </Col>
            <Col flexBasis="30%">
              <Text>株式会社ソデック</Text>
              <Text>〒104-0042</Text>
            </Col>
          </Grid>
          <Grid>
            <Col flexBasis="70%">
              <Grid>
                <Col
                  flexBasis="20%"
                  subTitle
                  style={{
                    alignItems: 'center', textAlign: 'center', justifyContent: 'center',
                  }}
                >
                  <View><Text>件名</Text></View>
                </Col>
                <Col
                  borderRight
                  borderTop
                  style={{
                    paddingLeft: '5px', paddingRight: '5px', minWidth: '260px', wordWrap: 'break-word', maxWidth: '260px',
                  }}
                >
                  {estimate.title && estimate.title.split('\n').map((t, i) => <Text key={`title${i + 1}`}>{t}</Text>)}
                </Col>
              </Grid>
              {estimate.contractType !== '3' && (
                <Grid>
                  <Col flexBasis="20%" subTitle style={{ alignItems: 'center' }}>
                    <View><Text>納期</Text></View>
                  </Col>
                  <Col
                    borderRight
                    borderTop
                    style={{
                      paddingLeft: '5px', paddingRight: '5px', minWidth: '260px', wordWrap: 'break-word', maxWidth: '260px',
                    }}
                  >
                    <Text>{estimate.deliveryDate}</Text>
                  </Col>
                </Grid>
              )}
              {estimate.contractType === '3' && (
                <Grid>
                  <Col flexBasis="20%" subTitle style={{ alignItems: 'center' }}>
                    <View><Text>契約期間</Text></View>
                  </Col>
                  <Col
                    borderRight
                    borderTop
                    style={{
                      paddingLeft: '5px', paddingRight: '5px', minWidth: '260px', wordWrap: 'break-word', maxWidth: '260px',
                    }}
                  >
                    <Text>{`${estimate.sagyoStDt}～${estimate.sagyoEdDt}`}</Text>
                  </Col>
                </Grid>
              )}
              <Grid>
                <Col flexBasis="20%" subTitle style={{ alignItems: 'center' }}>
                  <View><Text>支払条件</Text></View>
                </Col>
                <Col
                  borderRight
                  borderTop
                  style={{
                    paddingLeft: '5px', paddingRight: '5px', minWidth: '260px', wordWrap: 'break-word', maxWidth: '260px',
                  }}
                >
                  <Text>{estimate.paymentTerm}</Text>
                </Col>
              </Grid>
              <Grid>
                <Col flexBasis="20%" subTitle style={{ alignItems: 'center' }}>
                  <View><Text>有効期限</Text></View>
                </Col>
                <Col
                  borderRight
                  borderTop
                  borderBottom
                  style={{
                    paddingLeft: '5px', paddingRight: '5px', minWidth: '260px', wordWrap: 'break-word', maxWidth: '260px',
                  }}
                >
                  <Text>{estimate.deadline}</Text>
                </Col>
              </Grid>
            </Col>
            <Col flexBasis="30%">
              <Box style={{ position: 'relative' }}>
                <Text>東京都中央区入船1丁目4番10号</Text>
                <Text>八丁堀プレイス6F</Text>
                <Text>TEL：03-3297-3030</Text>
                {(estimate.eigyoTnto && isDispTanto) && (
                  <Text>{`担当：${estimate.eigyoTnto}`}</Text>
                )}
                <Text>登録番号：T9010001092589</Text>
                {estimate.approvalStatus === '4' && (
                  <Image
                    src={syaban}
                    style={{
                      width: '40%', position: 'absolute', top: '-15px', right: '0px',
                    }}
                  />
                )}
              </Box>
            </Col>
          </Grid>
          <Grid style={{ marginTop: '15px' }}>
            <Col flexBasis="70%">
              <Grid style={{ height: '40px' }}>
                <Box
                  flexBasis="20%"
                  subTitle
                  style={{
                    alignItems: 'center', justifyContent: 'center', display: 'flex', border: '1px solid #E4E4E4', backgroundColor: '#E4E4E4', width: '70px', fontSize: '15px',
                  }}
                >
                  <View><Text>合計</Text></View>
                </Box>
                <Box style={{
                  alignItems: 'center', marginRight: '10px', justifyContent: 'center', display: 'flex', border: '1px solid #E4E4E4', width: '250px', fontSize: '15px',
                }}
                >
                  <Text>{`${estimate.hachuKingakuIncluded ? estimate.hachuKingakuIncluded : 0}円 (税込)`}</Text>
                </Box>
              </Grid>
            </Col>
            <Col flexBasis="30%" />
          </Grid>
          <Grid style={{ marginTop: '20px', textAlign: 'center' }}>
            <Col subTitle flexBasis="5%"><Text>No.</Text></Col>
            <Col subTitle flexBasis="50%"><Text>摘要</Text></Col>
            <Col subTitle flexBasis="10%"><Text>数量</Text></Col>
            <Col subTitle flexBasis="15%"><Text>単価</Text></Col>
            <Col subTitle flexBasis="20%"><Text>金額</Text></Col>
          </Grid>
          {detail.map((item) => (
            <Grid key={item.displayOrder}>
              <Col borderLeft borderBottom flexBasis="5%" style={{ textAlign: 'center', justifyContent: 'center' }}><Text>{item.displayOrder}</Text></Col>
              <Col borderLeft borderBottom flexBasis="50%" style={{ paddingLeft: '5px' }}>
                {item.summary && item.summary.split('\n').map((t, i) => <Text key={`summary${i + 1}`}>{t}</Text>)}
              </Col>
              <NumberCol borderLeft borderBottom flexBasis="10%"><Text>{`${item.quantity.toLocaleString()}`}</Text></NumberCol>
              <NumberCol borderLeft borderBottom flexBasis="15%"><Text>{`¥${item.unitPrice.toLocaleString()}`}</Text></NumberCol>
              <NumberCol borderLeft borderBottom borderRight flexBasis="20%"><Text>{`¥${item.totalPrice.toLocaleString()}`}</Text></NumberCol>
            </Grid>
          ))}
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="10%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>小計</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${estimate.hachuKingaku ? estimate.hachuKingaku : 0}`}</Text></NumberCol>
          </Grid>
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="10%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>消費税</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${estimate.tax ? estimate.tax : 0}`}</Text></NumberCol>
          </Grid>
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="10%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>合計</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${estimate.hachuKingakuIncluded ? estimate.hachuKingakuIncluded : 0}`}</Text></NumberCol>
          </Grid>
          <Box style={{ marginTop: '15px' }}>
            <Text>上記の通り、注文いたします。</Text>
          </Box>
          <Grid>
            <Col flexBasis="60%">
              <Box style={{ border: '1px solid #000', padding: '5px' }}>
                <Text>日付：</Text>
                <Grid style={{ marginTop: '15px' }}>
                  <Col flexBasis="90%">
                    <Text>会社名：</Text>
                  </Col>
                  <Col flexBasis="10%" style={{ flexDirection: 'row' }}>
                    <Box style={{ border: '1px dotted #3e3e3e', padding: '10px' }}>
                      <Text>印</Text>
                    </Box>
                  </Col>
                </Grid>
                <Grid>
                  <Col flexBasis="90%">
                    <Text>役職：</Text>
                  </Col>
                  <Col flexBasis="10%" />
                </Grid>
                <Grid style={{ marginTop: '25px', marginBottom: '10px' }}>
                  <Col flexBasis="90%">
                    <Text>氏名：</Text>
                  </Col>
                  <Col flexBasis="10%" />
                </Grid>
              </Box>
            </Col>
            <Col flexBasis="40%" />
          </Grid>
          <Grid style={{ minHeight: '70px', marginTop: '20px' }}>
            <Col flexBasis="10%" style={{ textAlign: 'center', justifyContent: 'center' }} subTitle><Text>備考</Text></Col>
            <Col borderTop borderRight borderBottom flexBasis="90%" style={{ paddingLeft: '10px', justifyContent: 'center' }}>
              {estimate.externalBiko && estimate.externalBiko.split('\n').map((t, i) => <Text key={`externalBiko${i + 1}`}>{t}</Text>)}
            </Col>
          </Grid>
        </Root>
      </Page>
    </Document>
  );
}
